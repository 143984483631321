
import * as S from "./employee-info-modal.styled";
import * as Dialog from "@radix-ui/react-dialog";
import * as Slider from "@radix-ui/react-slider";

import { EmployeeInfoModalType } from "./employee-info-modal.type";
import { differenceInYears, formatDate } from 'date-fns';
import { useEmployeeInfoHook } from "./employee-info-modal.hook";
import birthday_icon from '../../../assets/icons/birthday.svg';
import edit_icon from '../../../assets/icons/Edit.svg';
import wallet_icon from '../../../assets/icons/Wallet.svg';
import { Profile } from "../profile";
import { ButtonComponent } from "../button";
import { EmployeeConfirmModalComponent } from "../employeeConfirmModal";
import { CardEdit } from "../cardEdit";
import { CardNameEmployee } from "../cardNameEmployee";
import { baseUrl } from "../../../api/api";
import { CardHistoryReward } from "../cardHistoryReward";
import { SLIDER_LIMIT } from "../../../utils/config";
import { TeamProfilesComponent } from "../teamProfiles";
import { LoadingComponent } from "../loading";
import { DialogComponent } from "../dialog";
import { ModalNewEmployee } from "../modalNewEmployee";
import { ModalDeleteUser } from "../modalDeleteUser";
import { ModalNewManager } from "../modalNewManager";


export const EmployeeInfoModal = ({ user, type, onClose }: EmployeeInfoModalType) => {
    const hook = useEmployeeInfoHook({ user, type, onClose});

    return (
        <S.EmployeeInfoModalContainer>
            <S.Container>
                <S.ContainerLeft>
                    {hook.isLoadingRecognitionsData || hook.isRefetchingRecognitionsData ?
                        <LoadingComponent /> :
                        <>
                            <Profile user={hook.userData} height={type === "EMPLOYEE" ? 172 : 210} type={type} />

                            <div className="info-time">
                                <div className="data">
                                    <p className="c1-regular">Admissão em  {hook.userData?.admissionDate && formatDate(hook.userData.admissionDate, 'dd/MM/yyyy')}</p>
                                </div>
                                <div className="tempo">
                                    <img src={birthday_icon} alt="icone de festa" />
                                    <p>{hook.userData?.admissionDate && differenceInYears(new Date(), hook.userData.admissionDate)} anos na Chemtrade</p>
                                </div>
                            </div>

                            {type === "EMPLOYEE" &&
                                <DialogComponent
                                    title="Recompensar Funcionário"
                                    isOpen={hook.isOpenModal === user?.id}
                                    onOpen={() => hook.setIsOpenModal(user?.id!)}
                                    onClose={() => hook.setIsOpenModal(null)}
                                    width={418}
                                    content={<EmployeeConfirmModalComponent userId={hook.userData?.id!} onClose={hook.handleReload} />}
                                >
                                    <>
                                        {hook.userData?.myTeam?.fkManagerId === parseInt(hook.authUser.user_id) || hook.authUser.isManager ?
                                            <ButtonComponent
                                                buttonStyle="primary" width={512}
                                                onClick={() => hook.setIsOpenModal(user?.id!)}
                                            >
                                                Recompensar Funcionário
                                            </ButtonComponent> :
                                            <div className="empty-input"></div>
                                        }
                                    </>
                                </DialogComponent>

                            }

                            <S.InfoPessoal>
                                {hook.authUser.isManager &&
                                    <div className="edit">
                                        <CardEdit options={hook.editOptions}/>
                                    </div>
                                }

                                <div className="info-user">
                                    <div className="cpf">
                                        <p className="c1-regular">CPF</p>
                                        <p className="b2-regular">{hook.userData?.cpf}</p>
                                    </div>

                                    <div className="email">
                                        <p className="c1-regular">E-mail corporativo</p>
                                        <p className="b2-regular">{hook.userData?.email}</p>
                                    </div>
                                </div>

                                {type === "EMPLOYEE" ?
                                    <>
                                        <p className="c1-regular titulo">Gestor</p>
                                        <S.Gestor>
                                            <CardNameEmployee
                                                nome={hook.userData?.myTeam?.userManager?.name || "Nenhum"}
                                                url={hook.userData?.myTeam?.userManager?.profilePic?.content}
                                            />
                                        </S.Gestor>
                                    </> :
                                    <S.TeamContainer>
                                        <div className="team">
                                            <p className="c1-regular">Equipe</p>
                                            <TeamProfilesComponent imageWidth={32} team={hook.userData?.managerTeam?.employeers} />
                                        </div>
                                        <S.ManagerGestor>
                                            <p className="c1-regular">Gestor</p>
                                            <CardNameEmployee
                                                nome={hook.userData?.managerTeam?.userSecondaryManager?.name || "Nenhum"}
                                                url={hook.userData?.managerTeam?.userSecondaryManager?.profilePic?.content}
                                            />
                                        </S.ManagerGestor>
                                    </S.TeamContainer>
                                }

                            </S.InfoPessoal>
                        </>
                    }
                </S.ContainerLeft>

                <S.ContainerRight>
                    {type === "EMPLOYEE" ?
                        <>
                            <S.Icones>
                                {hook.userData?.recognitionCategoriesCount?.map(recognition => (
                                    <S.Icone data-active={recognition.count > 0}>
                                        <img src={`${baseUrl}/${recognition.category.svg?.content}`} alt="icone de perfil" />
                                        <p className="b3-regular">x{recognition.count}</p>
                                    </S.Icone>
                                ))}

                            </S.Icones>

                            <S.Points>
                                <img src={wallet_icon} alt="icone de carteira " />
                                <p>{hook.userData?.wallet?.ammount} pts na carteira</p>
                            </S.Points>
                        </> :
                        <S.LimitContainer>
                            <p className="c1-regular titulo">Limite de Reconhecimento</p>
                            <S.Limite>
                                <form>
                                    {hook.generalSettingsData?.limitManagerRewards &&
                                        <Slider.Root defaultValue={[hook.generalSettingsData.limitManagerRewards]} max={SLIDER_LIMIT} step={1} disabled={true}>
                                            <S.SlideRoot>
                                                <Slider.Track className="SliderTrack">
                                                    <Slider.Range className="SliderRange" />
                                                </Slider.Track>
                                                <Slider.Thumb className="SliderThumb" aria-label="Volume" />
                                            </S.SlideRoot>
                                        </Slider.Root>
                                    }
                                </form>
                                <div>
                                    <img src={wallet_icon} alt="icone de Wallet" />
                                    <p className='b3-regular'>{hook.generalSettingsData?.limitManagerRewards} pts</p>
                                </div>

                            </S.Limite>
                        </S.LimitContainer>
                    }

                    <S.History data-height={type === 'EMPLOYEE' ? 382 : 422}>
                        <S.HistoryContent >
                            <p className="c1-regular">Histórico de reconhecimento</p>
                            {hook.isLoadingRecognitionsData || hook.isRefetchingRecognitionsData ?
                                <LoadingComponent /> :
                                <>
                                    {hook.recognitionsData && hook.recognitionsData.count === 0 ?
                                        <span className="c3-regular span-empty">Nenhum reconhecimento</span> :
                                        <>
                                            {hook.recognitionsData?.rows.map(recognition => (
                                                <CardHistoryReward recognition={recognition} />
                                            ))}
                                        </>
                                    }

                                </>

                            }

                        </S.HistoryContent>
                    </S.History>

                </S.ContainerRight>
            </S.Container>

            <DialogComponent
                title={`Editar ${type === 'EMPLOYEE' ? "Funcionário" : "Gestor"}`}
                isOpen={hook.isOpenEditModal === user?.id}
                onOpen={()=>hook.setIsOpenEditModal(user?.id!)}
                onClose={()=>hook.setIsOpenEditModal(null)}
                width={418}
                content={
                    type==='EMPLOYEE' ?
                        <ModalNewEmployee employee={hook.userData} onClose={hook.handleReload}/> :
                        <ModalNewManager manager={hook.userData} onClose={hook.handleReload}/>
                }
            >
                <></>
            </DialogComponent>

            <DialogComponent
                title={`Excluir ${type === 'EMPLOYEE' ? "Funcionário" : "Gestor"}`}
                isOpen={hook.isOpenDeleteModal === user?.id}
                onOpen={()=>hook.setIsOpenDeleteModal(user?.id!)}
                onClose={()=>hook.setIsOpenDeleteModal(null)}
                width={418}
                content={<ModalDeleteUser user={hook.userData!} onClose={()=>hook.setIsOpenDeleteModal(null)} onReload={hook.handleReloadDelete} type={type}/>}
            >
                <></>
            </DialogComponent>
        </S.EmployeeInfoModalContainer >
    )
}