import * as S from './employee-confirm-modal.styled';

import { EmployeeConfirmModalType } from './employee-confirm-modal.type';
import { ModalSuccess } from "../modalSuccess";
import { SelectComponent } from "../selectComponent";
import { ButtonComponent } from "../button";
import { useEmployeeConfirmModalHook } from "./employee-confirm-modal.hook";
import { formatDate } from "date-fns";
import { ptBR } from "date-fns/locale";
import coins from '../../../assets/icons/coin.svg';
import calendario from '../../../assets/icons/calendario.svg';
import * as Slider from "@radix-ui/react-slider";
import { LoadingComponent } from '../loading';

export const EmployeeConfirmModalComponent = ({ className, onClose, userId }: EmployeeConfirmModalType) => {
    const hook = useEmployeeConfirmModalHook(userId, onClose);

    return (
        <S.ModalConfirmEmployeeContainer className={className}>
            {hook.showModalSuccess ?
                <ModalSuccess message={hook.author.isManager ? "Funcionário recompensado!" : "Recompensa solicitada!"} /> :
                <form onSubmit={hook.handleSubmit(hook.onSubmit)}>
                    <S.ModalConfirmEmployeeContent>
                        <S.ModalConfirmEmployeeSelect>
                            <p className="c1-regular">Tipo de reconhecimento</p>
                            <SelectComponent selected={hook.selectedCategory} setSelected={hook.handleSelectCategory} error={hook.errors?.fkCategoryIds?.message} />
                        </S.ModalConfirmEmployeeSelect>

                        <S.points>
                            <p className='s3-bold'>+{hook.value[0]} pts</p>
                            <img src={coins} alt="icone de moeda" />
                        </S.points>

                        {hook.refetch ? 
                            <LoadingComponent containerHeight={20} size={20}/> :
                            <Slider.Root
                                className='SliderRoot'
                                onValueChange={(value) => hook.setValue(value)}
                                defaultValue={[hook.value[0]]}
                                max={hook.selectedCategory?.points || 1}
                                min={0}
                                step={1}
                            >
                                <S.SlideRoot>
                                    <Slider.Track className="SliderTrack">
                                        <Slider.Range className="SliderRange" />
                                    </Slider.Track>
                                    <Slider.Thumb className="SliderThumb" aria-label="Volume" />
                                </S.SlideRoot>
                            </Slider.Root>
                        }

                        <S.TextArea>
                            <p className='c1-regular'>Motivo do reconhecimento</p>
                            <textarea className='b2-regular' id="reason" placeholder='Motivo do reconhecimento' {...hook.register('reason')}></textarea>
                            <span className='c1-regular error'>{hook.errors?.reason?.message}</span>
                        </S.TextArea>

                        <S.ModalConfirmEmployeeSelect>
                            <p className="c1-regular">Calendário de resgate</p>
                            <S.Calendario>
                                <p className="b2-regular">
                                    {hook.rewardCalendarData?.startActivationDay && formatDate(hook.rewardCalendarData?.startActivationDay, 'dd MMMM', { locale: ptBR })} -
                                    {hook.rewardCalendarData?.endActivationDay && formatDate(hook.rewardCalendarData?.endActivationDay, ' dd MMMM', { locale: ptBR })}
                                </p>
                                <img src={calendario} alt="icone de calendario" />
                            </S.Calendario>
                        </S.ModalConfirmEmployeeSelect>
                    </S.ModalConfirmEmployeeContent>

                    <ButtonComponent buttonStyle="primary" type="submit" disabled={hook.isLoadingSubmit}>
                        {hook.isLoadingSubmit ? "Recompensando..." : "Confirmar"}
                    </ButtonComponent>
                </form>
            }
        </S.ModalConfirmEmployeeContainer>

    );
};
