import { setToastMessage } from "../features/general-slice";
import { useAppDispatch } from "../store";
import { LocalToken } from "../utils/local-token";
import api from "./api";

export const responseStatus = {
    SUCCESS : 'SUCCESS',
    NOT_FOUND : 'NOT_FOUND',
    ERROR : 'ERROR'
}

export function useHandleRequest(){
    const dispatch = useAppDispatch();

    async function handleRequest(apiEndpoint: string, method: string, data?: any, errorMessage?: string) {
        try {
            let response;
            let config = {};

            if (data instanceof FormData) {
                config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };
            }

            switch (method.toUpperCase()) {
                case 'GET':
                    response = await api.get(apiEndpoint);
                    break;
                case 'POST':
                    response = await api.post(apiEndpoint, data, config);
                    break;
                case 'PUT':
                    response = await api.put(apiEndpoint, data, config);
                    break;
                case 'DELETE':
                    response = await api.delete(apiEndpoint);
                    break;
                default:
                    throw new Error('Invalid HTTP method');
            }
    
            if (response.status >= 200 && response.status < 300) {
                return {
                    status: responseStatus.SUCCESS,
                    data: response.data,
                };
            } else {
                throw response;
            }

        } catch (err: any) {
            const error = err.response.data;

            if (error.error === 'AuthorizationError') {
                LocalToken.clearStoredToken();
                window.location.reload();
            } else {
                let messageError = error.error === 'Error' ? error.message : 'Ocorreu um erro inesperado';
                dispatch(setToastMessage(messageError));
                setTimeout(() => {
                    dispatch(setToastMessage(''));
                }, 5000);
            }

            return {
                status: err.response.status === 404 ? responseStatus.NOT_FOUND : responseStatus.ERROR,
                data: errorMessage ? errorMessage : err,
            };
        }
    }

    return {
        handleRequest,
        responseStatus
    }
}
