import * as SA from '../../../../app.styled';
import { useHistoryHook } from './hook';
import { TabBarComponent } from '../../../components/tabBar';
import { HeaderPageComponent } from '../../../components/headerPage';
import { LoadingComponent } from '../../../components/loading';
import { InputSearchComponent } from '../../../components/inputSearch';

export const HistoryPage = () => {
    const hook = useHistoryHook();

    return (
        <SA.AppContainerHome>
            <SA.AppTopBar>
                <HeaderPageComponent title={"Atualizações"} onUpdate={() => hook.refetchRecongnitions()} />
            </SA.AppTopBar>
            <TabBarComponent
                tabs={hook.tabsList}
                selectedTab={hook.selectedTab}
                setSelectedTab={hook.setSelectedTab}
                alert={{
                    index: 0,
                    value: hook?.pendingCount
                }}
            />

            <InputSearchComponent placeholder={'Busca'} setSearch={hook.setSearch} refetch={hook.refetchRecongnitions}/>

            {hook.isLoadingRecognition || hook.isRefetchingRecognition ?
                <LoadingComponent containerHeight={250}/> :
                <>
                    {hook.tabs[hook.selectedTab]}
                </>
            }
        </SA.AppContainerHome>
    );
}
