import { useMutation, useQuery } from "react-query";
import { useState } from "react";
import { RecognitionSchemaType, recognitionSchema } from "./employee-confirm-modal.type";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import { useRewardCalendarService } from "../../../services/reward-calendar.service";
import { useRecognitionService } from "../../../services/recognition.service";
import { RecognitionCategoryInterface } from "../../../models/recoginition-category";
import { useAppSelector } from "../../../hooks/hooks";
import { RewardCalendarInterface } from "../../../models/reward-calendar.interface";
import { queryClient } from "../../../utils/query-client";
import { responseStatus } from "../../../api/api-request.service";
import { RecognitionInterface } from "../../../models/recognition.interface";

export function useEmployeeConfirmModalHook(userId: number, onClose?: Function) {
    const rewardCalendarService = useRewardCalendarService();
    const recognitionService = useRecognitionService()
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<RecognitionCategoryInterface>();
    const [value, setValue] = useState<number[]>([0]);
    const [refetch, setRefetch] = useState(false);
    const author = useAppSelector(state => state.user);

    const {
        data: rewardCalendarData,
        isLoading: isLoadingRewardCalendarData
    } = useQuery<RewardCalendarInterface>('getRewardCalendar', getRewardCalendarByUserId);

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
            (data: RecognitionSchemaType) => handleSubmitForm(data), {
            onSuccess: () => {
                queryClient.invalidateQueries('createRecognition');
            },
        }
    );

    const { register, handleSubmit, control, formState: { errors } } = useForm<RecognitionSchemaType>({
        resolver: zodResolver(recognitionSchema)
    });

    const { append, remove } = useFieldArray({
        control,
        name: 'fkCategoryIds'
    });

    function onSubmit(data: RecognitionSchemaType) {
        mutate(data);
    }

    async function getRewardCalendarByUserId() {
        const { status, data } = await rewardCalendarService.getRewardsCalendar();
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function handleCreateRecognition(payload: RecognitionSchemaType) {
        const recognition: RecognitionInterface = {
            ...payload,
            fkUserId: userId,
            fkAuthorId: parseInt(author.user_id),
            fkRecognitionCategoryId: selectedCategory?.id,
        };

        if(author.isManager){
            recognition.status = 'APPROVED';
            recognition.fkApprovedByUserId = parseInt(author.user_id);
            recognition.fkConfirmedByUserId = parseInt(author.user_id);
        }else if(!author.data.managerTeam?.fkSecondaryManagerId){
            recognition.status = 'CONFIRMED';
            recognition.fkConfirmedByUserId = parseInt(author.user_id);
        }else{
            recognition.status = 'PENDING';
        }

        const { status } = await recognitionService.createRecognition(recognition);
        if (status === responseStatus.SUCCESS) {
            setShowModalSuccess(true);
            if (onClose) {
                setTimeout(() => {
                    onClose();
                    queryClient.refetchQueries('getAllUpdateCardHome');
                }, 2000);
            }
        }
    }

    async function handleSubmitForm(payload: RecognitionSchemaType) {
        await handleCreateRecognition({...payload, points : value?.[0]});
    };

    function handleSelectCategory(category: RecognitionCategoryInterface) {
        setRefetch(true);
        setSelectedCategory(category);
        setValue([category.points]);
        remove(0);
        append({ categoryId: category.id });
        setTimeout(()=>{
            setRefetch(false);
        }, 300)
        
    }

    return {
        rewardCalendarData,
        isLoadingRewardCalendarData,
        showModalSuccess,
        selectedCategory,
        isLoadingSubmit,
        errors,
        author,
        handleSelectCategory,
        handleSubmit,
        onSubmit,
        register,
        value,
        setValue,
        refetch
    }
}