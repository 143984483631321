import { useQuery } from 'react-query'

import { responseStatus } from '../../../../api/api-request.service';

import { useRecognitionService } from '../../../../services/recognition.service';
import { RecognitionDataType } from '../../../../models/recognition.interface';
import { useEffect, useState } from 'react';
import { HistoryTabsType } from './type';
import { HistoryPaddingTab } from '../historyPending';
import { HistoryAllTab } from '../historyAll';
import { useAppSelector } from '../../../../hooks/hooks';


export function useHistoryHook() {
    const recognitionService = useRecognitionService();
    const [pendingCount, setPendingCount] = useState(0);
    const [selectedTab, setSelectedTab] = useState<keyof HistoryTabsType>('Pendente');
    const [search, setSearch] = useState('');
    const userAuth = useAppSelector(state=>state.user);
    const {
        data: recognitionData,
        isLoading: isLoadingRecognition,
        isRefetching: isRefetchingRecognition,
        refetch: refetchRecongnitions
    } = useQuery<RecognitionDataType>('getAllUpdateCard', userAuth.isManager ? getRecognitionByStatus : getRecognitions);

    const tabs = {
        'Pendente' : <HistoryPaddingTab recognitions={recognitionData?.rows} updateRecognitions={refetchRecongnitions}/>,
        'Todas' : <HistoryAllTab recognitions={recognitionData?.rows} updateRecognitions={refetchRecongnitions}/>
    };

    const tabsList = Object.keys(tabs);

    async function getRecognitionByStatus() {
        const { status, data } = await recognitionService.getRecognitionByStatus({status : selectedTab === "Pendente" ? "CONFIRMED" : "", search, fkSectorId : selectedTab === "Pendente" ? 10 : undefined});
        if (status === responseStatus.SUCCESS) {
            if(selectedTab === "Pendente"){
                setPendingCount(data.count);
            }
            return data
        }
    }

    async function getRecognitions() {
        if(userAuth.isManager !== null){
            const { status, data } = await recognitionService.getRecognitions({authorId : userAuth.user_id, status: selectedTab === "Pendente" ? 'PENDING' : '', search});
            if (status === responseStatus.SUCCESS) {
                if(selectedTab === "Pendente"){
                    setPendingCount(data.count);
                }
                return data;
            }
        }

    }


    useEffect(()=>{
        refetchRecongnitions();
    }, [selectedTab])


    return { 
        tabsList,
        tabs,
        recognitionData, 
        isLoadingRecognition,
        isRefetchingRecognition,
        selectedTab,
        pendingCount,
        setSelectedTab,
        refetchRecongnitions,
        setSearch
    };
}
