import * as S from './cardSidebarTopPageEmployee.styled'
import { CardSidebarTopPageEmployeeType } from './cardSidebarTopPageEmployee.type'

export const CardSidebarTopPageEmployee = ({ role }: CardSidebarTopPageEmployeeType) => {

    return (
        <S.CardTopContainer className="c1-regular">
            <div className='role-text'>
                <span>{role}</span>
            </div>
            <div className='recognitions-text'>
                <span >Reconhecimentos</span>
            </div>
            <div className='wallet-text'>
                <span>Pontos na Carteira</span>
            </div>
            <div className='manager-text'>
                <span>Gestor</span>
            </div>
            <div className='date-text'>
                <span>Última recompensa</span>
            </div>
            
        </S.CardTopContainer >
    );

}