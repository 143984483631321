import styled from "styled-components";

export const GivenContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const GivenContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`;

export const GivenCard = styled.div`
    display: flex;
    width: 349px;
    height: 130px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 8px;
    background: var(--minimal-color);
`;

export const GivenCardTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    img{
        display: flex;
        width: 20px;
        height: 20px;
        padding: 2px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 128px;
        background: var(--blue-color-100);
    }

    span{
        color : var(--blue-color-100);
    }
`;

export const GivenCardDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
`;