import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../../utils/query-client";
import { EmployeeDataType, EmployeeInterface } from "../../../models/employee.interface";
import { useEmployeeService } from "../../../services/employee.service";
import { responseStatus } from "../../../api/api-request.service";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import { ChangeEvent, useEffect, useState } from "react";
import { useManagerService } from "../../../services/manager.service";
import { ModalNewManagerHookType, getManagerSchema } from "./modalNewManager.type";

import base64 from 'base64-encode-file';
import { z } from "zod";
import { useSectorService } from "../../../services/sector.service";
import { SectorDataType } from "../../../models/sector.interface";
import { EmployeeCard } from "../employeeCard";


export function useModalNewManagerHook({ onClose, initialData }: ModalNewManagerHookType) {
    const managerService = useManagerService();
    const sectorsService = useSectorService();
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [search, setSearch] = useState('');
    const [searchEmployee, setSearchEmployee] = useState('');
    const [showMSelectManager, setShowMSelectManager] = useState(false);
    const [showMSelectEmployee, setShowMSelectEmployee] = useState(false);
    const [selectedManager, setSelectedManager] = useState<EmployeeInterface | undefined>(initialData?.myTeam?.userManager);
    const [selectedEmployee, setSelectedEmployee] = useState<EmployeeInterface>();
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState<EmployeeInterface[]>(initialData?.managerTeam?.employeers?.map(employee=>({...EmployeeCard, employeeId : employee.id})) || []);
    const [file, setFile] = useState<unknown>();
    const managerSchema = getManagerSchema(initialData?.id);
    type ManagerSchemaType = z.infer<typeof managerSchema>;

    console.log(initialData?.managerTeam?.employeers);

    const { mutate, isLoading: isLoadingSubmit } = useMutation(
        (data: ManagerSchemaType) => handleSubmitForm(data), {
        onSuccess: () => {
            queryClient.invalidateQueries('createManager');
        },
    });

    const {
        data: managerData,
        isLoading: isLoadingManagersData,
        refetch: refetchManager
    } = useQuery<EmployeeDataType>('getAllManagers', getAllManagers);

    const { register, handleSubmit, control, reset, formState: { errors } } = useForm<ManagerSchemaType>({
        resolver: zodResolver(managerSchema),
    });


    const {
        data: sectorsData,
        isLoading: isLoadingSectorsData,
        isRefetching: isRefetchingSectorsData,
        refetch: refetchsectorsData
    } = useQuery<SectorDataType>('getAllSectors', getAllSectors);

    const { append, remove } = useFieldArray({
        control,
        name: 'fkManagerIds'
    });

    const { fields, append: appendEmployee, remove: removeEmployee } = useFieldArray({
        control,
        name: 'employees'
    });

    async function getAllManagers() {
        const { status, data } = await managerService.getAllManagers({ search: debouncedSearch });
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }


    async function getAllSectors() {
        const { status, data } = await sectorsService.getSector();
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function handleSubmitForm(payload: ManagerSchemaType) {
        await handleCreateManager(payload);
    };

    async function handleCreateManager(payload: ManagerSchemaType) {

        let manager: EmployeeInterface = {
            ...payload,
            isBlocked: false,
            id: initialData?.id,
            sector: { id: parseInt(payload.fkSectorId) },

            managerTeam: {
                fkSecondaryManagerId: selectedManager?.id,
                employeers: selectedTeam.map(employee => ({ id: employee.employeeId }))
            }
        };

        if (file) {
            manager = {
                ...manager,
                profilePic: {
                    type: 'base64',
                    filetype: 'Image',
                    content: file.toString()
                }
            };
        }

        const { status } = initialData?.id ?
            await managerService.editManager({ data: manager }) :
            await managerService.createManager({ data: manager });
        if (status === responseStatus.SUCCESS) {
            setShowModalSuccess(true);
            if (onClose) {
                setTimeout(() => {
                    onClose();
                }, 2000);
            }
        }
    }

    function onSubmit(data: ManagerSchemaType) {
        mutate(data);
    }

    function handleSelectManager(manager: EmployeeInterface) {
        setSelectedManager(manager);
        setShowMSelectManager(false);
        remove(0);
        if (manager) {
            append({ managerId: manager.id });
        }
    }

    function handleBlurSearch() {
        setTimeout(() => {
            setShowMSelectManager(false);
        }, 300);
    }

    function handleFocusSearch() {
        setShowMSelectManager(true);
    }

    function handleBlurSearchEmployee() {
        setTimeout(() => {
            setShowMSelectManager(false);
        }, 300);
    }

    function handleFocusSearchEmployee() {
        setShowMSelectManager(true);
    }

    function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedManager(undefined);
        setSearch(e.target.value);
    }

    function handleSearchEmployee(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedEmployee(undefined);
        setSearchEmployee(e.target.value);
    }

    async function handleProfileImageChange(event: ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.[0];
        if (file) {
            const newFile = await base64(file);
            setFile(newFile);
        }
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [search]);

    useEffect(() => {
        refetchManager();
    }, [debouncedSearch]);

    useEffect(() => {
        if (initialData && managerData) {
            setSelectedManager(managerData?.rows.find(manager => manager.id === initialData?.managerTeam?.fkSecondaryManagerId));

            const employeeIds = fields.map(field => field.employeeId);

            initialData.managerTeam?.employeers?.forEach(employee => {
                if (!employeeIds.includes(employee.id)) {
                    appendEmployee({ ...employee, employeeId: employee.id });
                }
            });
        }
    }, [managerData, initialData]);

    useEffect(() => {
        reset({
            ...initialData,
            admissionDate: initialData?.admissionDate ? new Date(initialData.admissionDate).toISOString().slice(0, 10) : '',
            fkSectorId: initialData?.sector?.id?.toString()
        } as ManagerSchemaType);
    }, [initialData])

    return {
        errors,
        isLoadingSubmit,
        showModalSuccess,
        managerData,
        selectedManager,
        selectedEmployee,
        showMSelectManager,
        search,
        searchEmployee,
        showMSelectEmployee,
        isOpenModal,
        selectedTeam,
        file,
        sectorsData,
        handleProfileImageChange,
        setIsOpenModal,
        register,
        handleSubmit,
        onSubmit,
        handleSelectManager,
        handleBlurSearch,
        handleBlurSearchEmployee,
        handleFocusSearch,
        handleFocusSearchEmployee,
        handleSearch,
        handleSearchEmployee,
        setSelectedTeam,
        appendEmployee,
        removeEmployee,
        fields

    }
}
