import { useHandleRequest } from "../api/api-request.service";
import { EmployeeDataType, EmployeeInterface } from "../models/employee.interface";
import { getDefaultQuery } from "../utils/query-client";

type GetAllEmployeesType = {
    orderBy: string;
    search?: string;
    withoutTeam?: boolean;
    order?: string;
    page: number;
    fkManagerId?: string;
}

type CreateEmployeeType = {
    data: EmployeeInterface
}

type DeleteUserType = {
    userId: number
}

export function useEmployeeService() {
    const handleRequestHook = useHandleRequest();

    async function getAllEmployees({ orderBy, search, withoutTeam, order, page, fkManagerId }: GetAllEmployeesType): Promise<{ status: string; data: EmployeeDataType }> {
        withoutTeam = withoutTeam === undefined ? false : withoutTeam;
        const filters = [{ orderBy }, { withoutTeam }, { name: search }, { order }, { fkManagerId }];
        const query = getDefaultQuery({ filters, page });
        return handleRequestHook.handleRequest(`/user-employee${query}`, 'GET');
    }

    async function createEmployee({ data }: CreateEmployeeType): Promise<{ status: string; data: EmployeeInterface }> {
        return handleRequestHook.handleRequest('/user-employee', 'POST', data);
    }

    async function uploadManagers(file: File) {    
        const formData = new FormData();
        formData.append('file', file);
    
        return handleRequestHook.handleRequest('/uploads/sheets-manager/', 'POST', formData);
    }

    async function uploadEmployees(file: File) {    
        const formData = new FormData();
        formData.append('file', file);
    
        return handleRequestHook.handleRequest('/uploads/sheets-employee/', 'POST', formData);
    }


    async function editEmployee({ data }: CreateEmployeeType): Promise<{ status: string; data: EmployeeInterface }> {
        return handleRequestHook.handleRequest(`/user/${data.id}`, 'PUT', data);
    }

    async function deleteUser({ userId }: DeleteUserType) {
        return handleRequestHook.handleRequest(`/user/${userId}`, 'DELETE');
    }

    return {
        getAllEmployees,
        createEmployee,
        editEmployee,
        deleteUser,
        uploadManagers,
        uploadEmployees
    }
}