import { styled } from "styled-components";

export const ModalConfirmEmployeeContainer = styled.div`
    form{
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
`;

export const SlideRoot = styled.div`

  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 300px;
  height: 24px;
  cursor:pointer;



  .SliderTrack {
    background-color: var(--blue-color-20);
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
    height: 3px;
  
  }
  
  .SliderRange {
    position: absolute;
    background-color: var(--blue-color-100);
    border-radius: 9999px;
    height: 100%;
  
  }
  
  .SliderThumb {
    display: flex;
    width: 20px;
    height: 20px;
    background-color: var(--blue-color-100);
    box-shadow: 0 2px 10px v;
    border-radius: 10px;
    
  }
  .SliderThumb:hover {
    background-color:  var(--blue-color-100);
  }
  .SliderThumb:focus {
    outline: none;
 
  }
  

`

export const ModalConfirmEmployeeContent = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 4px;
    background: var(--minimal-color);
`;

export const ModalConfirmEmployeeSelect = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
`;


export const points = styled.div`
 margin-top:16px;
 display:flex;
 flex-direction:row;
 justify-content:center;
 align-items:center;
 border-radius: 8px;
 padding:16px;
 gap:4px;
 background-color: var(--white-color);
  height:72px;
  width:335px;
 img{
  width: 16px;
  height: 16px;
 }
 p{
  color:var(--blue-color-100);
 }
`

export const TextArea = styled.div`
 margin-top:8px;


 & textarea {
  display: flex;
  height: 78.5px;
  width:335px;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--white-color);
  margin-top:8px;
 border:none;
 outline:none;
 resize:none;

 }
 & p{
  margin-left:4px;
 }




`

export const Calendario = styled.div`
display: flex;
height: 44px;
padding: 8px 16px;
justify-content: space-between;
align-items: center;
align-self: stretch;
width:335px;
border-radius: 8px;
background: var(--white-color);


`
