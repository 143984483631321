import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { EmployeeDataType } from "../../../models/employee.interface";
import { useEmployeeService } from "../../../services/employee.service";
import { responseStatus } from "../../../api/api-request.service";
import { SelectEmployeeHookType } from "./select-employee.type";
import { useManagerService } from "../../../services/manager.service";
import { useUserLoginService } from "../../../services/user-login.service";

export function useSelectEmployeeHook({id, managerId, setSelectedEmployee, setListEmployees, type, withoutManager, withoutTeam} : SelectEmployeeHookType){
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [search, setSearch] = useState('');
    const employeeService = useEmployeeService();
    const managerService = useManagerService();
    const userLoginService = useUserLoginService();
    const [page, setPage] = useState(1);

    const {
        data: employeesData,
        isLoading: isLoadingEmployeesData,
        isRefetching: isRefetchingEmployeesData,
        refetch: refetchEmployees
    } = useQuery<EmployeeDataType>(`get${type}Select${id}`, type==="EMPLOYEE" ? 
        managerId ? getManagerEmployees : getAllEmployees : getAllManagers
    );

    async function getAllEmployees() {        
        const { status, data } = await employeeService.getAllEmployees({orderBy : 'NAME', search, withoutTeam, page});
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    async function getManagerEmployees() {
        if(managerId){
            const { status, data } = await userLoginService.getUserLogin(managerId);
            if (status === responseStatus.SUCCESS) {
                const employeers = data.managerTeam.employeers;
                return {count: employeers.length, rows: employeers};
            }
        }
    }

    async function getAllManagers() {
        const { status, data } = await managerService.getAllManagers({search, withoutManager});
        if (status === responseStatus.SUCCESS) {
            return data;
        }
    }

    function handleBlur(){
        setTimeout(()=>{
            setIsPopoverOpen(false);
        }, 500);
    }

    function handleFocus(){
        setIsPopoverOpen(true);
    }

    function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        if(setSelectedEmployee){
            setSelectedEmployee(undefined);
        }
        setSearch(e.target.value);
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [search]);

    useEffect(() => {
        refetchEmployees();
    }, [debouncedSearch]);

    useEffect(()=>{
        if(setListEmployees){
            setListEmployees(employeesData?.rows);
        }
    }, [employeesData]);

    return{
        isPopoverOpen,
        employeesData,
        isLoadingEmployeesData,
        isRefetchingEmployeesData,
        search,
        handleBlur,
        handleFocus,
        handleSearch
    }
}