import { useState } from "react";
import { EmployeeInterface } from "../../../models/employee.interface";
import { SelectTeamHandleCheckType, SelectTeamHookType } from "./select-team.type";


export function useSelectTeamHook({onSubmit, onClose, selecteds, onAdd, onRemove, initialData} : SelectTeamHookType){
    const [emplyees, setEmployees] = useState<EmployeeInterface[]>();
    const [selectedsEmployees, setSelectedsEmployees] = useState<EmployeeInterface[]>(selecteds);

    function handleCheck({e, employee} : SelectTeamHandleCheckType){
        if(!selectedsEmployees.find(selected=>selected.employeeId === employee.id)){
            const newEmployees = [...selectedsEmployees];
            newEmployees.push({...employee, employeeId : employee.id});
            setSelectedsEmployees(newEmployees);
            onAdd({...employee, employeeId : employee.id});
        }else{
            const index = selectedsEmployees.findIndex(item => item.employeeId === employee.id);
            setSelectedsEmployees(selectedsEmployees.filter(item=>item.employeeId !== employee.id));
            onRemove(index);
        }
    }

    function handleSubmit(){
        onSubmit(selectedsEmployees);
        onClose();
    }

    function handleSetEmployees(employees : EmployeeInterface[]){
        if(employees){
            const data = initialData ? [...initialData, ...employees] : employees;
            setEmployees(data);
        }

    }

    return{
        emplyees,
        selectedsEmployees,
        handleSetEmployees,
        setSelectedsEmployees,
        handleCheck,
        handleSubmit
    }

}